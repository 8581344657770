<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Trade</h1>
      </div>
      <TradeForm :viewMode="true" :eventName="'updateTrade'" @updateTrade="updateTrade" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import TradeForm from '@/components/financial/TradeForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Financial from '@/lib/financial';

export default {
  name: 'Trade',
  components: {
    TradeForm,
  },
  mounted(){
    this.loadTrade();
  },
  methods:{
    async loadTrade(){
      try{
        const loginInfo = Common.getLoginInfo();
        const tradeId = this.$route.params.id;
        const trade = await Financial.loadTrade(this.apiUrl, tradeId, loginInfo);
        this.setCurrentTrade(trade);
      }catch(err){
        console.log(err);
      }
    },
    async updateTrade(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const tradeId = this.$route.params.id;
        const tradeInfo = Object.assign({}, form);

        const employee = await this.getEmployeeByName(form.employeeId);
        const employeeId = employee.id;
        tradeInfo.employeeId = employeeId;

        const trade = await Financial.updateTrade(this.apiUrl, tradeId, tradeInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    ...mapActions(['setCurrentTrade']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
